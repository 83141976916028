import { screensState } from "@/lib/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: screensState = {
  screenId: 0,
  title: "",
  description: "",
  showDots: false,
  image: "",
  showLogo: true,
  showHeader: true,
};

const screensSlice = createSlice({
  name: "screenId",
  initialState,
  reducers: {
    incremented: (state) => {
      state.screenId += 1;
    },
    decremented: (state) => {
      state.screenId -= 1;
    },
    initializeScreen: (state, action: PayloadAction<number>) => {
      state.screenId = action.payload;
    },

    headerData: (state, action) => {
      state.screenId = action.payload;
      switch (action.payload) {
        case 1:
          state.title = "Welcome";
          state.description = `Enter the mobile number you used at the time of purchase to get started. You’ll receive a verification code shortly.`;
          state.showDots = true;

          break;
        case 2:
          state.title = "Verification code";
          state.description = `A verification code has been sent to your mobile phone. Please enter it below to confirm your mobile number.`;
          state.showDots = true;

          break;
        case 3:
          state.title = "Buying a Lovegame";
          state.description = `To get you started, we just need a few details. Please complete the information below, it'll only take a moment.`;
          state.showDots = true;

          break;
        case 5:
          state.title = "Payment";
          state.showDots = true;
          state.showLogo = true;
          state.showHeader = true;

          break;
        case 6:
          state.title = "";
          state.description = "";
          state.showDots = false;
          state.showLogo = true;
          state.showHeader = true;
          break;
        case 7:
          state.title = "Congratulations";
          state.description = `Congratulations on taking the first step toward transforming your life!

You're almost ready to dive into the LoveGame universe, crafted to offer a one-of-a-kind journey.

Just follow the link below to download our exclusive app from the App Store. 

We’re excited for you to get started`;
          state.showDots = false;
          state.showLogo = true;
          state.showHeader = false;

          break;
        default:
          state.title = "";
          state.description = "";
          state.showDots = false;
          state.image = "";
          state.showLogo = true;
          state.showHeader = true;
          break;
      }
    },
  },
});

export const { incremented, decremented, initializeScreen, headerData } =
  screensSlice.actions;
export default screensSlice.reducer;
